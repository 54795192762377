import React from "react";
import { Link } from "react-router-dom";
import Image from "react-image-webp";
import Navbar from "../components/Navbar";
import Hero from "../components/hero";
import About from "../components/about";
import Features2 from "../components/Features2";
import Footer from "../components/footer/footer";
import Scrollbar from "../components/scrollbar";
import ballonHeartImg from "../assets/images/ballon-heart.jpg";
import ballonHeartImgWebP from "../assets/images/webp/ballon-heart.webp";
import data from "../assets/data.json";

const HomePage = () => {
  const pageData = data["home-page"];

  return (
    <div className="homepage">
      <Navbar />
      <Hero />
      <About />
      <Features2 features={pageData.features}/>
      <section className="testimonials-section section-padding no-background">
        <div className="container">
          <div className="row">
            <div className="col offset-md-3 col-xs-12">
              <div className="testimonial-content-active text-center">
                <h2>O SEU APOIO É IMPORTANTE!</h2>
                <div className="info">
                  <Link className="theme-btn" to="/donativo">
                    Faça a DIFERENÇA
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape1">
          <Image
            src={ballonHeartImg}
            webp={ballonHeartImgWebP}
            alt="bg shape"
            draggable="false"
          />
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </div>
  );
};
export default HomePage;
