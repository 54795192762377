import React from "react";

const Features2 = ({ features }) => (
  <div className="features-area">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-12">
          <div className="features-wrap">
            <div className="row">
              {features.map((item, index) => {
                return (
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div
                      className={`features-item ${item.name} bg-image-white`}
                    >
                      <div className="features-space" />

                      <div className="features-content">
                        <h2 dangerouslySetInnerHTML={{ __html: item.content }}/>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Features2;
